import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

export const MainBox = styled.div<{ moreSpaced: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.COLORS.border};
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.border};
  padding: ${SPACER.x2large} 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.COLORS.background};

  ${MEDIA_QUERIES.mdUp} {
    padding: ${({ moreSpaced }) =>
      moreSpaced ? '59px 0' : `${SPACER.x3large} 0`};
  }
`;

export const SectionTitle = styled.div`
  font-size: 20px;
  line-height: ${SPACER.xlarge};
  color: ${({ theme }) => theme.COLORS.primary};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};
  margin-bottom: ${SPACER.xlarge};
`;
