import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const MainBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${SPACER.x4large} 0 0;

  ${MEDIA_QUERIES.lgUp} {
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    max-width: 936px;
    margin: auto;
    padding: 6rem 0 0;
  }
`;

export const TextBox = styled.div`
  padding: 0 ${SPACER.large} 0;

  ${MEDIA_QUERIES.mdUp} {
    margin: 0 ${SPACER.medium} 0 0;
  }

  ${MEDIA_QUERIES.lgUp} {
    margin: 0 ${SPACER.x5large} 0 0;
    padding: 0;
    max-width: 336px;
  }
`;

export const TitleHeader = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.heading(theme)};
  margin-bottom: ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    ${({ theme }) => TYPOGRAPHY_STYLE.display(theme)};
    margin-bottom: ${SPACER.xlarge};
  }
`;

export const Sections = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Section = styled.div`
  color: ${({ theme }) => theme.COLORS.primary};
  margin-bottom: ${SPACER.xlarge};

  :last-of-type {
    margin-bottom: ${SPACER.x4large};
  }

  span {
    display: block;
    font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};
    padding-bottom: ${SPACER.medium};
    ${MEDIA_QUERIES.mdUp} {
      ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
      padding-bottom: ${SPACER.small};
    }
  }

  ${MEDIA_QUERIES.lgUp} {
    :last-of-type {
      margin-bottom: 0;
    }
  }
`;
