import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

import { LOCAL_SPACING } from 'styles/constants';
import { retinaBackground } from 'styles/mixins/retinaBackgroundImage';

import benefitsBanner from './content/images/benefitsBanner.jpg';
import benefitsBanner2x from './content/images/benefitsBanner@2x.jpg';

export const BannerBox = styled.div`
  background: url(${benefitsBanner});
  background-size: cover;
  background-position: 44% center;
  width: 100%;
  height: 302px;
  ${retinaBackground(`url(${benefitsBanner2x})`, 'cover', '44% center')}

  ${MEDIA_QUERIES.mdUp} {
    max-width: 936px;
    height: 345px;
    background-position: center;
    margin: auto;
  }
`;

export const TitleHeader = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
  margin: ${SPACER.x4large} auto ${SPACER.large};
  text-align: center;

  ${MEDIA_QUERIES.mdUp} {
    ${({ theme }) => TYPOGRAPHY_STYLE.display(theme)};
    line-height: ${SPACER.x5large};
    margin: 4.5rem auto ${SPACER.x3large};
  }
`;

export const Sections = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    width: 560px;
    margin: auto;
  }
`;

export const Section = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.border};

  svg {
    margin: ${LOCAL_SPACING.xsmall} auto ${SPACER.small};
  }

  :first-of-type {
    svg {
      margin-top: 0px;
    }
  }

  ${MEDIA_QUERIES.mdUp} {
    width: 140px;
    border: none;

    svg {
      margin: 0 auto ${LOCAL_SPACING.small};
    }
  }
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.COLORS.primary};
  margin: 0 auto ${LOCAL_SPACING.xsmall};
  text-align: center;

  br {
    display: none;
  }

  ${MEDIA_QUERIES.mdUp} {
    margin: 0 auto;
    font-weight: bold;
    br {
      display: block;
    }
  }
`;

export const BottomText = styled.div`
  width: 296px;
  margin: ${SPACER.x2large} auto ${SPACER.x4large};
  ${({ theme }) => TYPOGRAPHY_STYLE.caption(theme)};
  color: ${({ theme }) => theme.COLORS.primary};
  text-align: center;

  ${MEDIA_QUERIES.mdUp} {
    margin: ${SPACER.x4large} auto 6.5rem;
  }
`;
