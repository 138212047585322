import React from 'react';
import { HeartEmoji, CheckBadgeGlyph, FaceGlyph } from 'radiance-ui/lib/icons';

import {
  MainBox,
  TitleHeader,
  Description,
  Sections,
  Section,
  SectionTitle,
  SectionDescription,
} from './style';

const WhatWeCareAbout = () => (
  <MainBox>
    <TitleHeader>What we care about</TitleHeader>
    <Description>
      Our values run more than skin deep. We’re committed to building a safe,
      inclusive, fair, and diverse work community—where everyone can belong and
      thrive. If you believe in the following, we’d love to meet you.
    </Description>
    <Sections>
      <Section>
        <CheckBadgeGlyph width={48} height={48} />
        <SectionTitle>Accountability</SectionTitle>
        <SectionDescription>
          We consider problems from all angles, which means we ask our teammates
          for candid feedback. We own and discuss our mistakes so we can all
          learn and grow. Our goals are clear, our roles are clear, and we are
          committed. We take ownership of our mindset, we focus on solutions,
          and act to achieve our goals.
        </SectionDescription>
      </Section>
      <Section>
        <HeartEmoji width={48} height={48} />
        <SectionTitle>Team First</SectionTitle>
        <SectionDescription>
          We know the best results come when we work together in service of what
          is best for Curology. We choose the path toward collective success
          when we rally behind our shared goals, as one, unified team.
        </SectionDescription>
      </Section>
      <Section>
        <FaceGlyph width={48} height={48} />
        <SectionTitle>Inclusivity</SectionTitle>
        <SectionDescription>
          We believe diversity is a powerful differentiator that when unlocked
          allows us to make better decisions and drive better outcomes for our
          patients and our customers. We create an environment where everyone
          feels welcomed, included, and empowered to bring their unique
          backgrounds and perspectives to the conversation.
        </SectionDescription>
      </Section>
    </Sections>
  </MainBox>
);

export default WhatWeCareAbout;
