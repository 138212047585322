/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const CONTENT = {
  people: [
    {
      image: (
        <StaticImage
          src="./images/testimonies1@2x.jpg"
          alt="photo of Kirsten"
          width={350}
        />
      ),
      body: '“I love how working at Curology is a balance of being valued for who I am while also being challenged and stretched to develop who I can be. Everyday I’m surrounded by people who inspire me to level up.”',
      name: 'Kirsten (she/her/hers) — Curology alumna',
      department: 'People Operations',
      location: 'San Diego, CA',
    },
    {
      image: (
        <StaticImage
          src="./images/testimonies2@2x.jpg"
          alt="photo of Joaquin"
          width={350}
        />
      ),
      body: '“Working with our Curology Customer Success Team has been the most influential and supportive environment. It’s a great feeling making an impact with our patients while continuing to build our brand.”',
      name: 'Joaquin (he/him/his)',
      department: 'Customer Success',
      location: 'San Diego, CA',
    },
    {
      image: (
        <StaticImage
          src="./images/testimonies3@2x.jpg"
          alt="photo of Eric"
          width={350}
        />
      ),
      body: `“I came to Curology because I wanted to have a bigger impact. Since
      joining, I have been fortunate to work with a world-class engineering
      team, and together we have built tools used by our medical group to
      diagnose and treat our patients.”`,
      name: 'Eric (he/him/his)',
      department: 'Engineering',
      location: 'San Francisco, CA',
    },
    {
      image: (
        <StaticImage
          src="./images/testimonies4@2x.jpg"
          alt="photo of Jenn"
          width={350}
        />
      ),
      body: ` “I work with the most amazing co-workers I could ever imagine. People
      who are kind, passionate, excited to come to work every day—it’s truly
      a gift.”`,
      name: 'Jenn (she/her/hers)',
      department: 'Nurse Practitioner',
      location: 'San Diego, CA',
    },
    {
      image: (
        <StaticImage
          src="./images/testimonies5@2x.jpg"
          alt="photo of Nicole"
          width={350}
        />
      ),
      body: `“Every day is different at Curology—I love that I get to work in a
      fast-paced, collaborative environment, and I feel very grateful that I
      work with such creative, talented, and kindhearted people.”`,
      name: 'Nicole (she/her/hers)',
      department: 'Marketing',
      location: 'San Francisco, CA',
    },
  ],
};

export default CONTENT;
