import React from 'react';

import CtaButton from '../ctaButton';
import { MainBox, SectionTitle } from './style';

const CtaSection = ({
  buttonText = 'View open positions',
  sectionTitle,
  moreSpaced = false,
}: {
  buttonText?: string;
  sectionTitle?: string;
  moreSpaced?: boolean;
}) => (
  <MainBox moreSpaced={moreSpaced}>
    {sectionTitle && <SectionTitle>{sectionTitle}</SectionTitle>}
    <CtaButton buttonText={buttonText} module="ctaSection" />
  </MainBox>
);

export default CtaSection;
