import React from 'react';
import {
  PalmTreeGlyph,
  RocketshipGlyph,
  PackagingGlyph,
} from 'radiance-ui/lib/icons';

import {
  BannerBox,
  TitleHeader,
  Sections,
  Section,
  Description,
  BottomText,
} from './style';

const EmployeeBenefits = () => (
  <React.Fragment>
    <BannerBox />
    <TitleHeader>Employee benefits</TitleHeader>
    <Sections>
      <Section>
        <PalmTreeGlyph width={48} height={48} />
        <Description>
          PTO +<br /> Paid parental leave
        </Description>
      </Section>
      <Section>
        <RocketshipGlyph width={48} height={48} />
        <Description>Equity</Description>
      </Section>
      <Section>
        <PackagingGlyph width={48} height={48} />
        <Description>Free Curology subscription</Description>
      </Section>
    </Sections>
    <BottomText>
      and...
      <br />
      Health, dental, and vision insurance
      <br />
      Employee donation matching
      <br />
      401k plan
      <br />
      Flexible spending plans
      <br />
      Commuter benefits
      <br />
      Expensed educational courses
      <br />+ many more!
    </BottomText>
  </React.Fragment>
);

export default EmployeeBenefits;
