import React from 'react';

import TestimonyCard from './components/testimonyCard';
import Content from './content';
import { Sections, SpacerColumnSection, TitleHeader } from './style';

const Testimonies = () => (
  <React.Fragment>
    <TitleHeader>Great skin starts with great people</TitleHeader>
    <Sections>
      {Content.people.map((person) => (
        <TestimonyCard
          key={person.name}
          body={person.body}
          name={person.name}
          department={person.department}
          location={person.location}
          image={person.image}
        />
      ))}
      <SpacerColumnSection />
    </Sections>
  </React.Fragment>
);

export default Testimonies;
