import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

import { LOCAL_SPACING } from 'styles/constants';

export const MainBox = styled.div`
  padding: ${SPACER.x4large} ${SPACER.large};
  ${MEDIA_QUERIES.mdUp} {
    padding: 6.5rem 0;
  }
`;

export const TitleHeader = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.heading(theme)};

  ${MEDIA_QUERIES.mdUp} {
    ${({ theme }) => TYPOGRAPHY_STYLE.display(theme)};
    line-height: ${SPACER.x5large};
    text-align: center;
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.COLORS.primary};
  margin: ${SPACER.medium} 0 ${SPACER.x2large};

  ${MEDIA_QUERIES.mdUp} {
    max-width: 560px;
    margin: ${SPACER.large} auto ${SPACER.x5large};
    text-align: center;
  }
`;

export const Sections = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${MEDIA_QUERIES.mdUp} {
    margin: auto;
    padding: 0;
    flex-flow: row wrap;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
    padding: 0 ${SPACER.large};
    column-gap: ${SPACER.xlarge};
  }
  ${MEDIA_QUERIES.lgUp} {
    max-width: 936px;
    padding: 0;
    column-gap: 0;
  }
`;

export const Section = styled.div`
  margin-bottom: ${SPACER.xlarge};
  ${MEDIA_QUERIES.mdUp} {
    max-width: 390px;
    margin-bottom: ${SPACER.x5large};
  }

  ${MEDIA_QUERIES.lgUp} {
    margin-right: 156px;
    &:nth-of-type(even) {
      margin-right: 0px;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled.div`
  color: ${({ theme }) => theme.COLORS.primary};
  margin: 20px 0 ${LOCAL_SPACING.xsmall};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};

  ${MEDIA_QUERIES.mdUp} {
    margin: ${SPACER.xlarge} 0 ${SPACER.medium};
  }
`;

export const SectionDescription = styled.p`
  color: ${({ theme }) => theme.COLORS.primary};
`;
