import React from 'react';

import CtaButton from '../ctaButton';
import { HeaderBox, titleHeader } from './style';

const Header = () => (
  <HeaderBox>
    <h1 css={titleHeader}>
      Hi there! <br /> We’re Curology
    </h1>
    <CtaButton module="header" />
  </HeaderBox>
);

export default Header;
