import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

import { MainBox, TextBox, TitleHeader, Sections, Section } from './style';

const LifeAtCurology = () => (
  <MainBox>
    <TextBox>
      <TitleHeader>Life at Curology</TitleHeader>
      <Sections>
        <Section>
          <span>Balance work and life.</span>
          You’ve got a full life—and we embrace that. We wouldn’t want it any
          other way.
        </Section>
        <Section>
          <span>Keep learning.</span>
          Further your education—on Curology. Our employees work on personal
          development through trainings, seminars, courses, and more.
        </Section>
        <Section>
          <span>Make time for play.</span>
          We’re looking for fans of team outings, ice cream runs, and happy
          hours—bonus points if you like dogs.
        </Section>
        <Section>
          <span>Be unapologetically yourself.</span>
          Come as you are. We celebrate diversity and inclusion here—we embrace
          everything that makes you <i>you</i>.
        </Section>
      </Sections>
    </TextBox>
    <StaticImage
      src="./content/images/lifeAtCurologyMobile@2x.jpg"
      alt="photo of Curology employees"
      width={991}
      css={mobileOnly('block', 'lgUp')}
    />
    <StaticImage
      src="./content/images/lifeAtCurologyDesktop@2x.jpg"
      alt="photo of Curology employees"
      width={536}
      css={desktopOnly('block', 'lgUp')}
    />
  </MainBox>
);

export default LifeAtCurology;
