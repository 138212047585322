import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const TitleHeader = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.heading(theme)};
  margin: ${SPACER.x4large} ${SPACER.large} 0;
  width: 270px;
  ${MEDIA_QUERIES.mdUp} {
    ${({ theme }) => TYPOGRAPHY_STYLE.display(theme)};
    margin: 6.5rem auto 0;
    line-height: ${SPACER.x5large};
    text-align: center;
    width: inherit;
  }
`;

export const Sections = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  margin: ${SPACER.large} 0 ${SPACER.x2large};

  ${MEDIA_QUERIES.mdUp} {
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    margin: ${SPACER.x2large} 0 6.5rem;
  }
`;

export const SpacerColumnSection = styled.div`
  flex: 0 0 auto;
  width: ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    display: none;
  }
`;
