import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

import { LOCAL_SPACING } from 'styles/constants';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const MissionBox = styled.div`
  max-width: 664px;
  margin: ${SPACER.x4large} auto;
  padding: 0 ${SPACER.large};

  p,
  ul {
    color: ${({ theme }) => theme.COLORS.primary};
    margin-bottom: ${LOCAL_SPACING.medium};
  }

  ${MEDIA_QUERIES.mdUp} {
    margin: 6.5rem auto;
    text-align: center;
    padding: 0;
    p,
    ul {
      margin-bottom: ${LOCAL_SPACING.large};
    }
  }
`;

export const mobileImageStyles = css`
  width: 100%;
  ${mobileOnly()}
`;

export const desktopImageStyles = css`
  ${desktopOnly()}

  ${MEDIA_QUERIES.mdUp} {
    width: 100%;
    margin: 0 auto;
    max-width: 936px;
  }
`;
