import styled from '@emotion/styled';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

export const Description = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.caption(theme)};
  color: ${({ theme }) => theme.COLORS.primary};
  margin-top: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    ${({ theme }) => TYPOGRAPHY_STYLE.body(theme)};
    color: ${({ theme }) => theme.COLORS.primary};
    margin: 0;
    width: 323px;
  }
`;

export const DetailsWrapper = styled.div`
  text-align: left;
  display: inline-block;
`;

export const Name = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.caption(theme)};
  color: ${({ theme }) => theme.COLORS.primary};
  font-weight: bold;
  margin-top: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    margin-top: ${SPACER.large};
    margin-bottom: ${SPACER.xsmall};
  }
`;

export const Department = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.label(theme)};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
  display: flex;
  margin-bottom: ${SPACER.xsmall};
  flex-grow: 1;

  svg {
    margin-right: ${SPACER.small};
  }
`;

export const Location = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.label(theme)};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
  display: flex;
  flex-grow: 1;

  svg {
    margin-right: ${SPACER.small};
  }
`;

export const Section = styled.section`
  flex: 0 0 auto;
  margin-right: ${SPACER.large};
  width: 273px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: ${SPACER.medium};

  :first-of-type {
    margin-left: ${SPACER.large};
  }

  :last-of-type {
    margin-right: 0;
  }

  ${MEDIA_QUERIES.mdUp} {
    width: 704px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0 0 0;
    padding-bottom: 0;

    :first-of-type {
      margin: 0;
    }

    :nth-of-type(even) {
      ${Description} {
        order: 1;
        text-align: right;
      }

      & > div {
        order: 2;
      }
    }
  }
`;
