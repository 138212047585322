import styled from '@emotion/styled';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { css } from '@emotion/react';

import { retinaBackground } from 'styles/mixins/retinaBackgroundImage';

import headerMobile from './content/images/headerMobile.jpg';
import headerMobile2x from './content/images/headerMobile@2x.jpg';
import headerDesktop from './content/images/headerDesktop.jpg';
import headerDesktop2x from './content/images/headerDesktop@2x.jpg';

export const HeaderBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 612px;
  width: 100%;
  padding: 0 18px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(${headerMobile});
  background-size: cover;
  background-position: center;
  ${retinaBackground(
    `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      url(${headerMobile2x})`,
    'cover',
  )}

  ${MEDIA_QUERIES.mdUp} {
    position: relative;
    z-index: 0;
    height: 598px;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      url(${headerDesktop});
    background-size: cover;
    background-position: center;

    ${retinaBackground(
      `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(${headerDesktop2x})`,
      'cover',
      'center',
    )}
  }
`;

export const titleHeader = (theme: ThemeType) => css`
  color: ${theme.COLORS.white};
  margin: 132px 0 ${SPACER.x3large};
  text-align: center;

  ${MEDIA_QUERIES.mdUp} {
    margin: 177px 0 ${SPACER.large};

    br {
      display: none;
    }
  }
`;
