import React from 'react';

import { Nav } from 'components/global/Nav';
import { SEO } from 'components/global/SEO';
import { Footer } from 'components/global/Footer';
import Header from 'components/pages/careers/header';
import MissionSection from 'components/pages/careers/missionSection';
import WhatWeCareAbout from 'components/pages/careers/whatWeCareAbout';
import CtaSection from 'components/pages/careers/ctaSection';
import LifeAtCurology from 'components/pages/careers/lifeAtCurology';
import Testimonies from 'components/pages/careers/testimonies';
import EmployeeBenefits from 'components/pages/careers/employeeBenefits';

const CareersPageVersion = () => (
  <React.Fragment>
    <SEO
      title="Curology - Careers"
      description="Curology is the largest company in the customized skincare space. Our mission is simple: to make personalized skin care accessible and convenient for all."
    />
    <Nav />
    <Header />
    <MissionSection />
    <WhatWeCareAbout />
    <CtaSection buttonText="come join us" />
    <LifeAtCurology />
    <Testimonies />
    <EmployeeBenefits />
    <CtaSection sectionTitle="We can’t wait to meet you" moreSpaced />
    <Footer />
  </React.Fragment>
);

export default CareersPageVersion;
