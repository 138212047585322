import React from 'react';
import { BagIcon, LocationIcon } from 'radiance-ui/lib/icons';

import {
  Department,
  Description,
  DetailsWrapper,
  Location,
  Name,
  Section,
} from './style';

export interface TestimonyCardType {
  image: JSX.Element;
  body: string;
  department: string;
  location: string;
  name: string;
}

const testimonyCard = ({
  image,
  body,
  department,
  location,
  name,
}: TestimonyCardType) => {
  return (
    <Section>
      {image}
      <Description>
        <p>{body}</p>
        <DetailsWrapper>
          <Name>{name}</Name>
          <Department>
            <BagIcon displayInline fill="#332E54" />
            {department}
          </Department>
          <Location>
            <LocationIcon displayInline fill="#332E54" />
            {location}
          </Location>
        </DetailsWrapper>
      </Description>
    </Section>
  );
};

export default testimonyCard;
