import React from 'react';
import { LinkButton } from 'radiance-ui';

import { EXTERNAL_URLS } from 'consts/externalUrls';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { trackEvent, identifyUser } from 'utils/metricsTracking';

const CtaButton = ({
  buttonText = 'View open positions',
  module,
}: {
  buttonText?: string;
  module: string;
}) => {
  const onCtaClick = () => {
    identifyUser({
      patient: {},
      traits: {},
      callback: () => {
        trackEvent(EVENTS.ctaClicked, {
          path: window.location.pathname,
          module,
        });
      },
    });
  };

  return (
    <LinkButton
      href={EXTERNAL_URLS.careers}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onCtaClick}
    >
      {buttonText}
    </LinkButton>
  );
};

export default CtaButton;
